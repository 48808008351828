exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-bookings-booking-list-popup-tsx": () => import("./../../../src/pages/admin/bookings/BookingListPopup.tsx" /* webpackChunkName: "component---src-pages-admin-bookings-booking-list-popup-tsx" */),
  "component---src-pages-admin-bookings-bookings-tsx": () => import("./../../../src/pages/admin/bookings/Bookings.tsx" /* webpackChunkName: "component---src-pages-admin-bookings-bookings-tsx" */),
  "component---src-pages-admin-bookings-index-tsx": () => import("./../../../src/pages/admin/bookings/index.tsx" /* webpackChunkName: "component---src-pages-admin-bookings-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-index-bkp-tsx": () => import("./../../../src/pages/index-bkp.tsx" /* webpackChunkName: "component---src-pages-index-bkp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

