import React, { PropsWithChildren } from 'react';
import { Amplify } from 'aws-amplify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import awsconfig from '../aws-exports';

import LogoImage from '../images/logo.webp';

import '../styles/global.css';

Amplify.configure(awsconfig);

const theme = createTheme({
	palette: {
		primary: {
			main: '#B38A4C',
		},
		secondary: {
			main: '#B38A4C',
		},
	},
	// components: {
	// 	MuiButtonBase: {
	// 		defaultProps: {
	// 			style: {
	// 				borderRadius: 50,
	// 			},
	// 		},
	// 	},
	// },
});

const App = ({ children }: PropsWithChildren) => {
	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
				<div
					style={{
						height: 120,
						padding: '20px 0',
						textAlign: 'center',
						boxSizing: 'border-box',
					}}
				>
					<img src={LogoImage} alt='' style={{ maxHeight: '100%' }} />
				</div>
				{children}
			</LocalizationProvider>
		</ThemeProvider>
	);
};

export default App;
